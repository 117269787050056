<template>
  <transition
    name="slide-right"
    appear
    mode="out-in">
    <div>
      <!-- header -->
      <CurvedHeader
        v-if="!isSearchInit"
        title="Explore" />
      <ExplorerScreen
        v-if="!isSearchInit"
        @initSearch="initSearch" />
      <CoursesSearch
        v-else
        @terminateSearch="terminateSearch"
        :shouldOpenDialogBeforeSearchInit="shouldOpenDialogBeforeSearchInit" />
    </div>
  </transition>
</template>

<script>
  import ExploreMixin from '@/views/explore/Explore.mixin.vue';
  export default {
    mixins: [ExploreMixin],
  };
</script>

<style lang="css" scoped>
  .category-container {
    display: grid;
    position: relative;
  }
  .category-item {
    grid-area: 1 / 1;
  }
  .section-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--v-offBlack-base);
  }
</style>
